/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MapPin } from 'react-feather';

let mapkey = 'AIzaSyA5bfnlIrm89weVPEOJJsC6S5j-NmDs5TA';
if (process.env.GATSBYMAPKEY) {
  mapkey = process.env.GATSBYMAPKEY;
}

const GoogleMap = props => {
  const center = {
    lat: props.location.latitude,
    lng: props.location.longitude
  };
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapkey }}
        defaultCenter={center}
        defaultZoom={props.zoom}
      >
        <Marker
          lat={props.location.latitude}
          lng={props.location.longitude}
          text="Bentinck MW"
        />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;

const Marker = () => {
  return (
    <div style={{ color: 'red' }}>
      <MapPin />
    </div>
  );
};

GoogleMap.defaultProps = {
  center: {
    lat: 53.10262387993156,
    lng: -1.2693113580441615
  },
  zoom: 16
};
