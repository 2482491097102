/* eslint-disable react/prop-types */
import React from 'react';
import { MapPin, Smartphone, Mail } from 'react-feather';
import { graphql, useStaticQuery } from 'gatsby';

import PageHeader from '../components/PageHeader';
import FormSimpleAjax from '../components/FormSimpleAjax';
import RichContent from '../components/Content';
import GoogleMap from '../components/GoogleMap';
import Layout from '../components/Layout';

// Export Template for use in CMS preview
const ContactPageTemplate = ({
  Content,
  Title,
  Sub_Title,
  Featured_Image,
  featuredImageOffsetX,
  featuredImageOffsetY,
  Address,
  Phone,
  Email,
  Location
}) => {
  return (
    <main className="Contact">
      <PageHeader
        title={Title}
        subtitle={Sub_Title}
        backgroundImage={Featured_Image}
        imageOffsetX={featuredImageOffsetX}
        imageOffsetY={featuredImageOffsetY}
      />
      <section className="section Contact--Section1">
        <div className="container Contact--Section1--Container">
          <div>
            <RichContent source={Content} />
            <div className="Contact--Details">
              {Address && (
                <a
                  className="Contact--Details--Item"
                  href={`https://www.google.co.uk/maps/search/${encodeURI(
                    Address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {Address}
                </a>
              )}
              {Phone && (
                <a className="Contact--Details--Item" href={`tel:${Phone}`}>
                  <Smartphone /> {Phone}
                </a>
              )}
              {Email && (
                <a className="Contact--Details--Item" href={`mailto:${Email}`}>
                  <Mail /> {Email}
                </a>
              )}
            </div>
          </div>

          <div>
            <FormSimpleAjax name="Simple Form Ajax" />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <GoogleMap location={Location} />
        </div>
      </section>
    </main>
  );
};

const ContactPage = () => {
  const data = useStaticQuery(pageQuery);
  const page = data.directus.Contact_Page;

  return (
    <Layout meta={page.Default_Seo || false} title={page.Title || false}>
      <ContactPageTemplate {...page} />
    </Layout>
  );
};

export default ContactPage;

const pageQuery = graphql`
  query contactPage {
    directus {
      Contact_Page {
        Title
        Content
        Location
        Sub_Title
        Phone
        Email
        Address
        featuredImageOffsetX
        featuredImageOffsetY
        Featured_Image {
          id
        }
      }
    }
  }
`;
