/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
// import Helmet from 'react-helmet'
import { stringify } from 'qs';
import { serialize } from 'dom-form-serializer';

import './Form.css';

const Form = props => {
  const [alert, setAlert] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (disabled) return;

    const form = e.target;
    const data = serialize(form);
    setDisabled(true);
    fetch(`${form.action}?${stringify(data)}`, {
      method: 'POST'
    })
      .then(res => {
        if (res.ok) {
          return res;
        }
        throw new Error('Network error');
      })
      .then(() => {
        form.reset();
        setDisabled(false);
        setAlert(props.successMessage);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
        setDisabled(false);
        setAlert(props.errorMessage);
      });
  };

  const { name, subject, action } = props;

  return (
    <form
      className="Form"
      name={name}
      action={action}
      onSubmit={handleSubmit}
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      {alert && <div className="Form--Alert">{alert}</div>}
      <div className="Form--Group">
        <p className="ohnohoney">
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <label className="Form--Label">
          <input
            className="Form--Input Form--InputText"
            type="text"
            placeholder="Firstname"
            name="firstname"
            required
          />
          <span>Firstname</span>
        </label>
        <label className="Form--Label">
          <input
            className="Form--Input Form--InputText"
            type="text"
            placeholder="Lastname"
            name="lastname"
            required
          />
          <span>Lastname</span>
        </label>
      </div>
      <label className="Form--Label">
        <input
          className="Form--Input Form--InputText"
          type="email"
          placeholder="Email"
          name="emailAddress"
          required
        />
        <span>Email address</span>
      </label>
      <label className="Form--Label">
        <input
          className="Form--Input Form--InputText"
          type="text"
          placeholder="Telephone"
          name="phone"
          required
        />
        <span>Telephone</span>
      </label>
      <label className="Form--Label">
        <textarea
          className="Form--Input Form--Textarea Form--InputText"
          placeholder="Message"
          name="message"
          rows="10"
          required
        />
        <span>Message</span>
      </label>
      {!!subject && <input type="hidden" name="subject" value={subject} />}
      <input type="hidden" name="form-name" value={name} />
      <input
        className="Button Form--SubmitButton"
        type="submit"
        value="Enquire"
        disabled={disabled}
      />
    </form>
  );
};

Form.defaultProps = {
  name: 'Simple Form Ajax',
  subject: '', // optional subject of the notification email
  action: '',
  successMessage: 'Thanks for your enquiry, we will get back to you soon',
  errorMessage:
    'There is a problem, your message has not been sent, please try contacting us via email'
};
export default Form;
